import "@perimeter_81/ui/ui.umd.css";
import "safervpn-storybook/src/theme/fonts.css";
import "data/icons";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "store/store";
import api from "tools/apiConfigurator";
import { initGTM } from "tools/analytics";
import { getUserConfirmation } from "tools/getUserConfirmation";
import Authorization from "routes/Authorization";
import { palette } from "styles/theme/palette";
import {
  ThemeProvider,
  Notification,
  RightSideBarProvider,
  Loading,
  CssBaseline,
} from "@perimeter_81/ui";
import { injectEnvsScript, env } from "env";
import { useEffect } from "react";
import { useToggle } from "react-use";
import { isCheckpointDomain } from "./data/isCheckpointDomain";
import type { AppProps } from "types";

function App(props?: AppProps) {
  const [willMount, toggleWillMount] = useToggle(true);

  useEffect(() => {
    injectEnvsScript().then(() => {
      api.init();
      initGTM();
      toggleWillMount(false);
    });
    return () => {
      if (isCheckpointDomain) {
        // Styles clean up when changing to other apps in the Infinity Portal
        document
          .querySelectorAll("[data-meta=makeStyles]")
          .forEach((el) => el.remove());

        document
          .querySelectorAll("[name=p81styles]")
          .forEach((el) => el.remove());

        // Third party scripts cleanup
        document.querySelector(".intercom-lightweight-app")?.remove();
        document
          .querySelectorAll('[title*="Perimeter 81 Status"]')
          .forEach((el) => el.remove());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ThemeProvider palette={palette}>
      <CssBaseline />
      {willMount ? (
        <Loading />
      ) : (
        <Provider store={store}>
          <BrowserRouter
            basename={isCheckpointDomain ? env.REACT_APP_CP_BASENAME : ""}
            getUserConfirmation={getUserConfirmation}
          >
            <RightSideBarProvider>
              <Authorization {...props} />
              <Notification />
            </RightSideBarProvider>
          </BrowserRouter>
        </Provider>
      )}
    </ThemeProvider>
  );
}

export default App;
