import { logsTypes } from "data/constants";
import { ffKeys } from "tools/featureFlag";

const { auditLogs, usersActivityLogs, infrastructureEventsLogs } = logsTypes;

export default [
  {
    id: "infrastructure_tunnel",
    children: [
      {
        id: "TunnelChangedStatusEvent.UP",
        name: "TUNNEL_UP",
        type: infrastructureEventsLogs,
      },
      {
        id: "TunnelChangedStatusEvent.DOWN",
        name: "TUNNEL_DOWN",
        type: infrastructureEventsLogs,
      },
      {
        id: "TunnelErrorEvent",
        name: "TUNNEL_INITIATION_FAILED",
        type: infrastructureEventsLogs,
      },
    ],
  },
  {
    id: "Application",
    name: "APPLICATION",
    children: [
      {
        id: "api.activity.application.add",
        name: "APPLICATION_ADD",
        type: auditLogs,
      },
      {
        id: "api.activity.application.add.fail",
        name: "APPLICATION_ADD_FAIL",
        type: auditLogs,
      },
      {
        id: "api.activity.application.remove",
        name: "APPLICATION_REMOVE",
        type: auditLogs,
      },
      {
        id: "api.activity.application.update",
        name: "APPLICATION_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.application.enable",
        name: "APPLICATION_ENABLED",
        type: auditLogs,
      },
      {
        id: "api.activity.application.disable",
        name: "APPLICATION_DISABLED",
        type: auditLogs,
      },
      {
        id: "api.activity.policy.add",
        name: "POLICY_ADD",
        type: auditLogs,
      },
      {
        id: "api.activity.policy.delete",
        name: "POLICY_DELETE",
        type: auditLogs,
      },
      {
        id: "api.activity.policy.update",
        name: "POLICY_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.application.auth.success",
        name: "APPLICATION_AUTHORIZATION_SUCCESS",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.application.auth.fail",
        name: "APPLICATION_AUTHORIZATION_FAIL",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.application.session.start",
        name: "APPLICATION_SESSION_START",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.application.session.stop",
        name: "APPLICATION_SESSION_END",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.application.dynamicPropertiesResolve.success",
        name: "APPLICATION_DYNAMIC_PROPERTIES_RESOLVE_SUCCESS",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.application.dynamicPropertiesResolve.fail",
        name: "APPLICATION_DYNAMIC_PROPERTIES_RESOLVE_FAIL",
        type: usersActivityLogs,
      },
    ],
  },
  {
    id: "supportTeam",
    name: "SUPPORT_TEAM",
    children: [
      {
        id: "api.activity.supportTeam.login",
        name: "SUPPORT_TEAM_LOGIN",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.supportTeam.logout",
        name: "SUPPORT_TEAM_LOGOUT",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.supportAccess.granted",
        name: "SUPPORT_ACCESS_GRANTED",
        type: auditLogs,
      },
      {
        id: "api.activity.supportAccess.revoked",
        name: "SUPPORT_ACCESS_REVOKED",
        type: auditLogs,
      },
    ],
  },
  {
    id: "Role",
    name: "ROLE",
    children: [
      {
        id: "api.activity.role.assign",
        name: "ROLE_ASSIGN",
        type: auditLogs,
      },
    ],
  },
  {
    id: "Organization",
    name: "ORGANIZATION",
    accountType: "msp",
    distributorType: ["distributor", "reseller"],
    children: [
      {
        id: "api.activity.msp.org.create",
        name: "MSP_ORG_CREATE",
        type: auditLogs,
      },
      {
        id: "api.activity.msp.user.assigned.to.organizations",
        name: "MSP_USER_ASSIGNED_TO_ORGANIZATIONS",
        type: auditLogs,
      },
    ],
  },
  {
    id: "Password",
    name: "PASSWORD",
    children: [
      {
        id: "api.activity.password.reset",
        name: "PASSWORD_RESET",
        type: auditLogs,
      },
      {
        id: "api.activity.password.change",
        name: "PASSWORD_CHANGE",
        type: auditLogs,
      },
    ],
  },
  {
    id: "Group",
    name: "GROUP",
    children: [
      {
        id: "api.activity.group.create",
        name: "GROUP_CREATE",
        type: auditLogs,
      },
      {
        id: "api.activity.group.update",
        name: "GROUP_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.group.delete",
        name: "GROUP_DELETE",
        type: auditLogs,
      },
      {
        id: "api.activity.group.add.member",
        name: "GROUP_ADD_MEMBER",
        type: auditLogs,
      },
      {
        id: "api.activity.group.delete.member",
        name: "GROUP_DELETE_MEMBER",
        type: auditLogs,
      },
      {
        id: "api.activity.group.add.network",
        name: "GROUP_ADD_NETWORK",
        type: auditLogs,
      },
      {
        id: "api.activity.group.delete.network",
        name: "GROUP_DELETE_NETWORK",
        type: auditLogs,
      },
    ],
  },
  {
    id: "monitorLogs",
    name: "MONITOR_LOGS",
    children: [
      {
        id: "api.activity.webRule.logs.export",
        name: "WEB_ACTIVITY_EXPORT",
        type: auditLogs,
      },
      {
        id: "api.activity.activeSessions.logs.export",
        name: "ACTIVE_SESSIONS_EXPORT",
        type: auditLogs,
      },
      {
        id: "api.activity.audit.logs.export",
        name: "AUDIT_LOGS_EXPORT",
        type: auditLogs,
      },
      {
        id: "api.activity.infra.logs.export",
        name: "INFRA_LOGS_EXPORT",
        type: auditLogs,
      },
      {
        id: "api.activity.malware.events.rule.logs.export",
        name: "MALWARE_EVENTS_RULE_LOGS_EXPORT",
        type: auditLogs,
      },
    ],
  },
  {
    id: "Member",
    name: "MEMBER",
    children: [
      {
        id: "api.activity.member.invite",
        name: "MEMBER_INVITED",
        type: auditLogs,
      },
      {
        id: "api.activity.member.invite.accepted",
        name: "MEMBER_ACCEPTED_INVITE",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.member.delete",
        name: "MEMBER_DELETE",
        type: auditLogs,
      },
      {
        id: "api.activity.member.export",
        name: "MEMBER_EXPORT",
        type: auditLogs,
      },
      {
        id: "api.activity.member.create",
        name: "MEMBER_CREATE",
        type: auditLogs,
      },
      {
        id: "api.activity.member.update",
        name: "MEMBER_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.member.profile.update",
        name: "MEMBER_PROFILE_UPDATE",
        type: auditLogs,
      },
    ],
  },
  {
    id: "Billing",
    name: "BILLING",
    children: [
      {
        id: "api.activity.subscription.cancel",
        name: "SUBSCRIPTION_CANCEL",
        type: auditLogs,
      },
      {
        id: "api.activity.subscription.activate",
        name: "SUBSCRIPTION_ACTIVATE",
        type: auditLogs,
      },
      {
        id: "api.activity.subscription.reactivate",
        name: "SUBSCRIPTION_REACTIVATE",
        type: auditLogs,
      },
      {
        id: "api.activity.plan.change",
        name: "PLAN_CHANGE",
        type: auditLogs,
      },
      {
        id: "api.activity.payment.method.change",
        name: "PAYMENT_METHOD_CHANGE",
        type: auditLogs,
      },
    ],
  },
  {
    id: "License",
    name: "LICENSE",
    children: [
      {
        id: "api.activity.addon.user.buy",
        name: "LICENSE_ADD_MEMBER",
        type: auditLogs,
      },
      {
        id: "api.activity.addon.user.delete",
        name: "LICENSE_REMOVE_MEMBER",
        type: auditLogs,
      },
      {
        id: "api.activity.addon.gateway.buy",
        name: "LICENSE_ADD_GATEWAY",
        type: auditLogs,
      },
      {
        id: "api.activity.addon.gateway.delete",
        name: "LICENSE_REMOVE_GATEWAY",
        type: auditLogs,
      },
    ],
  },
  {
    id: "Addons",
    name: "ADDONS",
    children: [
      {
        id: "api.activity.addon.webSecurity.buy",
        name: "ADDON_WEB_SECURITY_BUY",
        type: auditLogs,
      },
    ],
  },
  {
    id: "INTEGRATION",
    name: "INTEGRATION",
    children: [
      {
        id: "api.activity.integration.create",
        name: "INTEGRATION_CREATE",
        type: auditLogs,
      },
      {
        id: "api.activity.integration.enable",
        name: "INTEGRATION_ENABLE",
        type: auditLogs,
      },
      {
        id: "api.activity.integration.disable",
        name: "INTEGRATION_DISABLE",
        type: auditLogs,
      },
      {
        id: "api.activity.integration.delete",
        name: "INTEGRATION_DELETE",
        type: auditLogs,
      },
    ],
  },
  {
    id: "apiKey",
    name: "API_KEY",
    children: [
      {
        id: "api.activity.apiKey.create",
        name: "API_KEY_CREATE",
        type: auditLogs,
      },
      {
        id: "api.activity.apiKey.delete",
        name: "API_KEY_DELETE",
        type: auditLogs,
      },
    ],
  },
  {
    id: "WebRules",
    name: "WEB_FILTER_RULES",
    children: [
      {
        // TODO: Once system activity is deprecated remove `{{rule.name}}` from `activity.json:181`.
        id: "api.activity.webRule.add",
        name: "WEB_FILTER_RULE_ADD",
        type: auditLogs,
      },
      {
        // TODO: Once system activity is deprecated remove `{{rule.name}}` from `activity.json:182`.
        id: "api.activity.webRule.update",
        name: "WEB_FILTER_RULE_UPDATE",
        type: auditLogs,
      },
      {
        // TODO: Once system activity is deprecated remove `{{rule.name}}` from `activity.json:183`.
        id: "api.activity.webRule.delete",
        name: "WEB_FILTER_RULE_DELETE",
        type: auditLogs,
      },
      {
        id: "api.activity.webRule.defaultAction.update",
        name: "WEB_FILTER_RULE_DEFAULT_ACTION_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.webRule.priority.update",
        name: "WEB_FILTER_RULE_PRIORITY_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.webRule.status.update",
        name: "WEB_FILTER_RULE_STATUS_UPDATE",
        type: auditLogs,
      },
    ],
  },
  {
    id: "TpRules",
    name: "THREAT_PREVENTION_RULES",
    ffKey:ffKeys.SWG_THREAT_PREVENTION,
    children: [
      {
        id: "api.activity.tpRule.add",
        name: "THREAT_PREVENTION_RULE_ADD",
        type: auditLogs,
      },
      {
        id: "api.activity.tpRule.update",
        name: "THREAT_PREVENTION_RULE_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.tpRule.delete",
        name: "THREAT_PREVENTION_RULE_DELETE",
        type: auditLogs,
      }
    ],
  },
  {
    id: "clientProfile",
    name: "CLIENT_PROFILE",
    children: [
      {
        id: "api.activity.clientProfile.create",
        name: "CLIENT_PROFILE_CREATE",
        type: auditLogs,
      },
      {
        id: "api.activity.clientProfile.update",
        name: "CLIENT_PROFILE_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.clientProfile.delete",
        name: "CLIENT_PROFILE_DELETE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.trusted.wired.add",
        name: "TRUSTED_WIRED_NETWORK_ADD",
        type: auditLogs,
      },
      {
        id: "api.activity.network.trusted.wired.delete",
        name: "TRUSTED_WIRED_NETWORK_DELETE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.trusted.wired.disable",
        name: "TRUSTED_WIRED_NETWORK_DISABLE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.trusted.wired.enable",
        name: "TRUSTED_WIRED_NETWORK_ENABLE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.trusted.wifi.add",
        name: "TRUSTED_WIFI_NETWORK_ADD",
        type: auditLogs,
      },
      {
        id: "api.activity.network.trusted.wifi.delete",
        name: "TRUSTED_WIFI_NETWORK_DELETE",
        type: auditLogs,
      },
    ],
  },
  {
    id: "IDP",
    name: "IDP",
    children: [
      {
        id: "api.activity.idp.create",
        name: "IDP_CREATE",
        type: auditLogs,
      },
      {
        id: "api.activity.idp.update",
        name: "IDP_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.idp.disable",
        name: "IDP_DISABLE",
        type: auditLogs,
      },
      {
        id: "api.activity.idp.delete",
        name: "IDP_DELETE",
        type: auditLogs,
      },
      {
        id: "api.activity.scim.enable",
        name: "SCIM_ENABLE",
        type: auditLogs,
      },
      {
        id: "api.activity.scim.disable",
        name: "SCIM_DISABLE",
        type: auditLogs,
      },
      {
        id: "api.activity.scim.token.create",
        name: "SCIM_TOKEN_CREATE",
        type: auditLogs,
      },
    ],
  },
  {
    id: "BypassRules",
    name: "BYPASS_RULES",
    children: [
      {
        // TODO: Once system activity is deprecated remove `{{rule.name}}` from `activity.json:187`.
        id: "api.activity.bypassRule.add",
        name: "BYPASS_RULE_ADD",
        type: auditLogs,
      },
      {
        // TODO: Once system activity is deprecated remove `{{rule.name}}` from `activity.json:188`.
        id: "api.activity.bypassRule.update",
        name: "BYPASS_RULE_UPDATE",
        type: auditLogs,
      },
      {
        // TODO: Once system activity is deprecated remove `{{rule.name}}` from `activity.json:189`.
        id: "api.activity.bypassRule.delete",
        name: "BYPASS_RULE_DELETE",
        type: auditLogs,
      },
      {
        id: "api.activity.bypassRule.priority.update",
        name: "BYPASS_RULE_PRIORITY_UPDATE",
        type: auditLogs,
      },
    ],
  },
  {
    id: "Login",
    name: "LOGIN",
    children: [
      {
        id: "api.activity.agent.token.receive.success",
        name: "LOGIN_SUCCESS",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.agent.token.receive.fail",
        name: "LOGIN_FAILED",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.agent.token.revoked.success",
        name: "AGENT_TOKEN_REVOKED_SUCCESS",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.agent.token.revoked.fail",
        name: "AGENT_TOKEN_REVOKED_FAIL",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.regularWeb.token.receive.success",
        name: "REGULAR_WEB_LOGIN_SUCCESS",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.regularWeb.token.receive.fail",
        name: "REGULAR_WEB_LOGIN_FAILED",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.regularWeb.user.bruteforce.blocked",
        name: "REGULAR_WEB_BRUTEFORCE_BLOCKED",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.regularWeb.ip.bruteforce.blocked",
        name: "REGULAR_WEB_BRUTEFORCE_IP_BLOCKED",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.user.bruteforce.blocked",
        name: "USER_BRUTEFORCE_BLOCKED",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.user.bruteforce.unblocked",
        name: "USER_BRUTEFORCE_UNBLOCKED",
        type: auditLogs,
      },
      {
        id: "api.activity.agent.device.register.success",
        name: "DEVICE_REGISTER_SUCCESS",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.agent.device.register.fail",
        name: "DEVICE_REGISTER_FAILED",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.agent.device.unregister.success",
        name: "AGENT_DEVICE_UNREGISTER_SUCCESS",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.agent.device.unregister.fail",
        name: "AGENT_DEVICE_UNREGISTER_FAIL",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.agent.network.permission.success",
        name: "VPN_AUTHENTICATION_SUCCESS",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.agent.network.permission.fail",
        name: "VPN_AUTHENTICATION_FAILED",
        type: usersActivityLogs,
      },
      {
        id: "api.activity.agent.network.logout",
        name: "AGENT_NETWORK_LOGOUT",
        type: usersActivityLogs,
      },
    ],
  },
  {
    id: "Object",
    name: "OBJECTS",
    children: [
      {
        id: "api.activity.address.update",
        name: "OBJECTS_ADDRESS_UPDATED",
        type: auditLogs,
      },
      {
        id: "api.activity.address.delete",
        name: "OBJECTS_ADDRESS_DELETED",
        type: auditLogs,
      },
      {
        id: "api.activity.address.add",
        name: "OBJECTS_ADDRESS_ADDED",
        type: auditLogs,
      },
      {
        id: "api.activity.service.add",
        name: "OBJECTS_SERVICE_ADDED",
        type: auditLogs,
      },
      {
        id: "api.activity.service.update",
        name: "OBJECTS_SERVICE_UPDATED",
        type: auditLogs,
      },
      {
        id: "api.activity.service.delete",
        name: "OBJECTS_SERVICE_DELETED",
        type: auditLogs,
      },
      {
        id: "api.activity.customUrl.add",
        name: "OBJECTS_CUSTOMURL_ADDED",
        type: auditLogs,
      },
      {
        id: "api.activity.customUrl.update",
        name: "OBJECTS_CUSTOMURL_UPDATED",
        type: auditLogs,
      },
      {
        id: "api.activity.customUrl.delete",
        name: "OBJECTS_CUSTOMURL_DELETED",
        type: auditLogs,
      },
    ],
  },
  {
    id: "PostureCheck",
    name: "POSTURE_CHECK",
    children: [
      {
        id: "api.activity.DPCProfile.update",
        name: "DPC_PROFILE_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.DPCProfile.create",
        name: "DPC_PROFILE_CREATE",
        type: auditLogs,
      },
      {
        id: "api.activity.DPCProfile.delete",
        name: "DPC_PROFILE_DELETE",
        type: auditLogs,
      },
      {
        id: "api.activity.DPC.check.failed",
        name: "DPC_PROFILE_FAILED",
        type: auditLogs,
      },
    ],
  },
  {
    id: "Network",
    name: "NETWORK",
    children: [
      {
        id: "api.activity.network.deploy",
        name: "NETWORK_DEPLOY",
        type: auditLogs,
      },
      {
        id: "api.activity.network.findByIdAndUpdate",
        name: "NETWORK_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.deploy.fail",
        name: "NETWORK_DEPLOY_FAIL",
        type: auditLogs,
      },
      {
        id: "api.activity.network.destroy",
        name: "NETWORK_DESTROY",
        type: auditLogs,
      },
      {
        id: "api.activity.network.region.add",
        name: "REGION_ADD",
        type: auditLogs,
      },
      {
        id: "api.activity.network.region.remove",
        name: "REGION_REMOVE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.scaleUnit.increase",
        name: "SCALE_UNITS_INCREASE",
        ffKey: ffKeys.NEW_GENERATION_NETWORK,
        type: auditLogs,
      },
      {
        id: "api.activity.network.scaleUnit.reduce",
        name: "SCALE_UNITS_REDUCE",
        ffKey: ffKeys.NEW_GENERATION_NETWORK,
        type: auditLogs,
      },
      {
        id: "api.activity.network.node.add",
        name: "NODE_ADD",
        type: auditLogs,
      },
      {
        id: "api.activity.network.node.custom.add",
        name: "NODE_CUSTOM_ADD",
        type: auditLogs,
      },
      {
        id: "api.activity.network.node.remove",
        name: "NODE_REMOVE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.node.restart",
        name: "NODE_RESTART",
        type: auditLogs,
      },
      {
        id: "api.activity.network.node.upgrade",
        name: "NODE_UPGRADE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.node.rebuild",
        name: "NODE_REBUILD",
        type: auditLogs,
      },
      {
        id: "api.activity.network.node.update.user.services",
        name: "NODE_UPDATE_USER_SERVICES",
        type: auditLogs,
      },
      {
        id: "api.activity.network.automatic.update",
        name: "NETWORK_AUTOMATIC_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.tunnel.add",
        name: "TUNNEL_ADD",
        type: auditLogs,
      },
      {
        id: "api.activity.network.node.change.state",
        name: "NODE_CHANGE_STATE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.region.change.state",
        name: "REGION_CHANGE_STATE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.tunnel.update",
        name: "TUNNEL_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.tunnel.restart",
        name: "TUNNEL_RESTART",
        type: auditLogs,
      },
      {
        id: "api.activity.network.tunnel.remove",
        name: "TUNNEL_REMOVE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.dnsFiltering.update",
        name: "DNS_FILTERING_UPDATE",
        feature: "dnsFiltering",
        type: auditLogs,
      },
      {
        id: "api.activity.network.dnsFiltering.remove",
        name: "DNS_FILTERING_REMOVE",
        feature: "dnsFiltering",
        type: auditLogs,
      },
      {
        id: "api.activity.network.privateDns.update",
        name: "PRIVATE_DNS_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.privateDns.enable",
        name: "PRIVATE_DNS_ENABLE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.privateDns.disable",
        name: "PRIVATE_DNS_DISABLE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.region.privateDns.update",
        name: "REGION_PRIVATE_DNS_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.region.privateDns.enable",
        name: "REGION_PRIVATE_DNS_ENABLE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.region.privateDns.disable",
        name: "REGION_PRIVATE_DNS_DISABLE",
        type: auditLogs,
      },
      {
        id: "api.activity.network.deploy.custom",
        name: "NETWORK_DEPLOY_CUSTOM",
        type: auditLogs,
      },
      {
        id: "api.activity.network.deploy.fail.custom",
        name: "NETWORK_DEPLOY_FAIL_CUSTOM",
        type: auditLogs,
      },
      {
        id: "api.activity.network.region.add.custom",
        name: "REGION_ADD_CUSTOM",
        type: auditLogs,
      },
      {
        id: "api.activity.network.splitTunneling",
        name: "NETWORK_SPLIT_TUNNELING",
        type: auditLogs,
      },
      {
        id: "api.activity.network.groups.change",
        name: "NETWORK_GROUPS_CHANGE",
        type: auditLogs,
      },
    ],
  },
  {
    id: "TrafficControl",
    name: "FIREWALL",
    children: [
      {
        id: "api.activity.networkPolicy.update",
        name: "NETWORK_POLICY_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.networkPolicy.update.fail",
        name: "NETWORK_POLICY_UPDATE_FAIL",
        type: auditLogs,
      },
      {
        // TODO: Once system activity is deprecated remove `<b>{{rule.name}}</b>` from `activity.json:93`.
        id: "api.activity.networkPolicy.rule.add",
        name: "NETWORK_POLICY_RULE_ADD",
        type: auditLogs,
      },
      {
        // TODO: Once system activity is deprecated remove `<b>{{rule.name}}</b>` from `activity.json:94`.
        id: "api.activity.networkPolicy.rule.update",
        name: "NETWORK_POLICY_RULE_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.networkPolicy.rule.priority.update",
        name: "NETWORK_POLICY_RULE_PRIORITY_UPDATE",
        type: auditLogs,
      },
      {
        id: "api.activity.networkPolicy.rule.delete",
        name: "NETWORK_POLICY_RULE_DELETE",
        type: auditLogs,
      },
      {
        id: "api.activity.firewallLogs.enable.fail",
        name: "ENABLE_FIREWALL_LOGS_FAIL",
        type: auditLogs,
        ffKey: ffKeys.FW_ENABLE_EVENTS,
      },
      {
        id: "api.activity.firewallLogs.enable.success",
        name: "ENABLE_FIREWALL_LOGS_SUCCESS",
        type: auditLogs,
        ffKey: ffKeys.FW_ENABLE_EVENTS,
      },
      {
        id: "api.activity.firewallLogs.disable.success",
        name: "DISABLE_FIREWALL_LOGS_SUCCESS",
        type: auditLogs,
        ffKey: ffKeys.FW_ENABLE_EVENTS,
      },
    ],
  },
];
