export default function (ROOT) {
  let paths = { ROOT };

  paths.ROOT = ROOT;

  paths.USER = ROOT + "/user";

  paths.SUPPORT_ACCESS = ROOT + "/support-access";

  paths.USER_PROFILE = paths.USER + "/profile";

  paths.USER_PASSWORD_CHANGE = paths.USER + "/password-change";

  paths.MONITOR_LOGS = ROOT + "/monitor-logs";

  paths.ACTIVITY = paths.MONITOR_LOGS + "/activity";

  paths.DEVICE_ACTIVITY = paths.MONITOR_LOGS + "/device-activity";

  paths.ACTIVE_SESSIONS = paths.MONITOR_LOGS + "/active-sessions";

  paths.WEB_ACTIVITY = paths.MONITOR_LOGS + "/web-activity";

  paths.ADMIN_ACTIVITY = paths.MONITOR_LOGS + "/admin-activity";

  paths.USERS_ACTIVITY = paths.MONITOR_LOGS + "/member-activity";

  paths.INFRASTRUCTURE_EVENTS = paths.MONITOR_LOGS + "/tunnels-status";

  paths.FIREWALL_EVENTS = paths.MONITOR_LOGS + "/firewall-events";

  paths.DEVICE_ACTIVITY_DETAILS =
    paths.DEVICE_ACTIVITY + "/:deviceId/:memberId/device-details";

  paths.MALWARE_PROTECTION_ACTIVITY =
    paths.MONITOR_LOGS + "/malware-protection";

  paths.TEAM = ROOT + "/team";

  paths.TEAM_MEMBERS = paths.TEAM + "/members";

  paths.TEAM_MEMBERS_INVITE = paths.TEAM_MEMBERS + "/invite";

  paths.TEAM_MEMBERS_CHANGE_ROLE =
    paths.TEAM_MEMBERS + "/:memberId/change-role";

  paths.TEAM_MEMBERS_EDIT_CUSTOMERS =
    paths.TEAM_MEMBERS + "/:memberId/edit-customers";

  paths.TEAM_MEMBERS_SHOW_INVITE =
    paths.TEAM_MEMBERS + "/:memberId/show-invite-link";

  paths.TEAM_MEMBERS_SIGN_OUT_CODE =
    paths.TEAM_MEMBERS + "/:memberId/signout-code";

  paths.TEAM_MANAGE_DEVICES = paths.TEAM_MEMBERS + "/:memberId/manage-devices";

  paths.TEAM_GROUPS = paths.TEAM + "/groups";

  paths.TEAM_GROUP = paths.TEAM_GROUPS + "/:groupId/members";

  paths.TEAM_GROUP_ASSIGN_MEMBERS = paths.TEAM_GROUP + "/assign";

  paths.TEAM_GROUPS_CREATE = paths.TEAM_GROUPS + "/create";

  paths.TEAM_GROUPS_EDIT = paths.TEAM_GROUPS + "/:groupId/edit";

  paths.TEAM_GROUPS_NETWORKS = paths.TEAM_GROUPS + "/:groupId/networks";

  paths.DEVICES = ROOT + "/devices";
  paths.DOWNLOADS = paths.DEVICES + "/downloads";
  paths.USER_CONFIGURATIONS = paths.DEVICES + "/configurations";

  paths.POSTURE_PROFILES = paths.DEVICES + "/posture-profiles";
  paths.ADD_POSTURE_CHECK_PROFILE = paths.POSTURE_PROFILES + "/add";
  paths.EDIT_POSTURE_CHECK_PROFILE = paths.POSTURE_PROFILES + "/:id/edit";
  paths.DUPLICATE_POSTURE_CHECK_PROFILE =
    paths.POSTURE_PROFILES + "/:id/duplicate";

  paths.DEVICE_INVENTORY = paths.DEVICES + "/device-inventory";
  paths.DEVICE_INVENTORY_DEVICE_PAGE =
    paths.DEVICE_INVENTORY + "/:deviceId/:memberId";

  paths.APPLICATION_ACCESS = ROOT + "/application-access";
  paths.APPLICATIONS = paths.APPLICATION_ACCESS + "/applications";
  paths.APPLICATIONS_CREATE = paths.APPLICATIONS + "/create";
  paths.APPLICATIONS_EDIT = paths.APPLICATIONS + "/:applicationId/edit";

  paths.POLICIES = paths.APPLICATION_ACCESS + "/policies";
  paths.POLICIES_CREATE = paths.POLICIES + "/create";
  paths.POLICIES_EDIT = paths.POLICIES + "/:policyId/edit";

  paths.NETWORK_ACCESS = ROOT + "/network-access";

  paths.NETWORKS = paths.NETWORK_ACCESS + "/networks";
  paths.NETWORKS_CREATE = paths.NETWORKS + "/create/:type?";
  paths.CHOOSE_NETWORK_TYPE = paths.NETWORKS + "/choose-network-type";

  paths.NETWORKS_NETWORK = paths.NETWORKS + "/:networkId";
  paths.NETWORKS_NETWORK_EDIT = paths.NETWORKS_NETWORK + "/edit";
  paths.NETWORKS_NETWORK_CREATE_REGIONS =
    paths.NETWORKS_NETWORK + "/create-regions";

  paths.NETWORK_POLICY_RULES = paths.NETWORK_ACCESS + "/traffic-control";

  paths.NETWORKS_NETWORK_CREATE_GATEWAYS =
    paths.NETWORKS_NETWORK + "/create-gateways/:regionId";
  paths.NETWORKS_NETWORK_MANAGE_SCALE_UNITS =
    paths.NETWORKS_NETWORK + "/manage-scaleunits/:regionId";
  paths.NETWORKS_NETWORK_SPLIT_TUNNELING =
    paths.NETWORKS_NETWORK + "/split-tunneling";
  paths.NETWORKS_NETWORK_CUSTOM_DNS = paths.NETWORKS_NETWORK + "/custom-dns";
  paths.NETWORKS_NETWORK_REGIONAL_CUSTOM_DNS =
    paths.NETWORKS_NETWORK + "/regional-custom-dns/:regionId";
  paths.NETWORKS_NETWORK_DNS_FILTERING =
    paths.NETWORKS_NETWORK + "/dns-filtering";
  paths.NETWORKS_NETWORK_NETWORK_GROUPS =
    paths.NETWORKS_NETWORK + "/network-groups";
  paths.NETWORKS_NETWORK_ROUTES_TABLE = paths.NETWORKS_NETWORK + "/routes";
  paths.NETWORKS_NETWORK_ADD_ROUTE =
    paths.NETWORKS_NETWORK_ROUTES_TABLE + "/add";
  paths.NETWORKS_NETWORK_EDIT_ROUTE =
    paths.NETWORKS_NETWORK_ROUTES_TABLE + "/:routeId/edit";

  paths.NETWORKS_NETWORK_CHOOSE_TUNNEL_TYPE =
    paths.NETWORKS_NETWORK + "/choose-tunnel-type/:regionId/:gatewayId";

  paths.NETWORKS_NETWORK_CHOOSE_IPSEC_TUNNEL_TYPE =
    paths.NETWORKS_NETWORK + "/choose-ipsec-tunnel-type/:regionId/:gatewayId";

  paths.NETWORKS_NETWORK_CREATE_IPSEC_TUNNEL =
    paths.NETWORKS_NETWORK + "/create-ipsec-tunnel/:regionId/:gatewayId";
  paths.NETWORKS_NETWORK_CREATE_REDUNDANT_IPSEC_TUNNEL =
    paths.NETWORKS_NETWORK + "/create-redundant-tunnel/:regionId/:gatewayId";
  paths.NETWORKS_NETWORK_EDIT_IPSEC_TUNNEL =
    paths.NETWORKS_NETWORK +
    "/edit-ipsec-tunnel/:regionId/:gatewayId/:tunnelId";
  paths.NETWORKS_NETWORK_EDIT_REDUNDANT_IPSEC_TUNNEL =
    paths.NETWORKS_NETWORK +
    "/edit-redundant-tunnel/:regionId/:gatewayId/:tunnelId";

  paths.NETWORKS_NETWORK_EDIT_CONNECTOR_TUNNEL =
    paths.NETWORKS_NETWORK +
    "/edit-connector-tunnel/:regionId/:gatewayId/:tunnelId";

  paths.NETWORKS_NETWORK_CREATE_CONNECTOR_TUNNEL =
    paths.NETWORKS_NETWORK + "/create-connector-tunnel/:regionId/:gatewayId";

  paths.NETWORKS_NETWORK_EDIT_OPENVPN_TUNNEL =
    paths.NETWORKS_NETWORK +
    "/edit-openvpn-tunnel/:regionId/:gatewayId/:tunnelId";

  paths.NETWORKS_NETWORK_CREATE_OPENVPN_TUNNEL =
    paths.NETWORKS_NETWORK + "/create-openvpn-tunnel/:regionId/:gatewayId";

  paths.NETWORKS_NETWORK_CONNECTOR_CONFIGURATION =
    paths.NETWORKS_NETWORK +
    "/connector-configuration/:regionId/:gatewayId/:tunnelId";

  paths.SETTINGS = ROOT + "/settings";

  paths.SETTINGS_ACCOUNT = paths.SETTINGS + "/account";

  paths.USER_ADD_CONFIGURATION = paths.USER_CONFIGURATIONS + "/add";
  paths.USER_EDIT_CONFIGURATION =
    paths.USER_CONFIGURATIONS + "/:profileId/edit";
  paths.USER_DUPLICATE_CONFIGURATION =
    paths.USER_CONFIGURATIONS + "/:profileId/duplicate";

  paths.SETTINGS_SECURITY = paths.SETTINGS + "/security";

  paths.SETTINGS_CERTIFICATE_MANAGER = paths.SETTINGS + "/certificates";
  paths.SETTINGS_CERTIFICATE_UPLOAD = paths.SETTINGS + "/certificates/upload";
  paths.SETTINGS_CERTIFICATE_EDIT = paths.SETTINGS + "/certificates/:id/edit";

  paths.SETTINGS_SECURITY_IDP_ADD = paths.SETTINGS_SECURITY + "/add-idp";
  paths.SETTINGS_SECURITY_IDP_ADD_GOOGLE_CLOUD_IDENTITY =
    paths.SETTINGS_SECURITY_IDP_ADD + "/google-cloud-identity";
  paths.SETTINGS_SECURITY_IDP_ADD_OKTA =
    paths.SETTINGS_SECURITY_IDP_ADD + "/okta";
  paths.SETTINGS_SECURITY_IDP_ADD_AZURE_AD =
    paths.SETTINGS_SECURITY_IDP_ADD + "/azure-ad";
  paths.SETTINGS_SECURITY_IDP_ADD_AD_LDAP =
    paths.SETTINGS_SECURITY_IDP_ADD + "/ad-ldap";

  // Edit identity provider paths

  paths.SETTINGS_SECURITY_IDP_EDIT_GOOGLE_CLOUD_IDENTITY =
    paths.SETTINGS_SECURITY + "/edit-idp/google-cloud-identity/:id";
  paths.SETTINGS_SECURITY_IDP_EDIT_OKTA =
    paths.SETTINGS_SECURITY + "/edit-idp/okta/:id";
  paths.SETTINGS_SECURITY_IDP_EDIT_AZURE_AD =
    paths.SETTINGS_SECURITY + "/edit-idp/azure-ad/:id";
  paths.SETTINGS_SECURITY_IDP_EDIT_AD_LDAP =
    paths.SETTINGS_SECURITY + "/edit-idp/ad-ldap/:id";
  paths.SETTINGS_SECURITY_IDP_EDIT_MFA =
    paths.SETTINGS_SECURITY + "/edit-mfa/:idp/:id";
  paths.SETTINGS_SECURITY_IDP_SCIM_SETTINGS =
    paths.SETTINGS_SECURITY + "/scim-settings/:idp";
  paths.SETTINGS_SECURITY_IDP_GOOGLE_CLOUD_IDENTITY_AUTH =
    paths.SETTINGS_SECURITY + "/google-cloud-identity-auth/:id";
  paths.SETTINGS_SECURITY_IDP_AD_LDAP_APP =
    paths.SETTINGS_SECURITY + "/ad-ldap-helper-app/:id";
  paths.SETTINGS_SECURITY_IDP_ADD_SAML =
    paths.SETTINGS_SECURITY + "/add-idp/saml";
  paths.SETTINGS_SECURITY_IDP_EDIT_SAML =
    paths.SETTINGS_SECURITY + "/edit-idp/saml/:id";

  paths.SETTINGS_SECURITY_IDP_LIMIT_ACCESS =
    paths.SETTINGS_SECURITY + "/limit-access";

  paths.SETTINGS_SECURITY_IDP_LIMIT_ACCESS_GOOGLE_CLOUD_IDENTITY =
    paths.SETTINGS_SECURITY_IDP_LIMIT_ACCESS + "/google-cloud-identity/:id";
  paths.SETTINGS_SECURITY_IDP_LIMIT_ACCESS_OKTA =
    paths.SETTINGS_SECURITY_IDP_LIMIT_ACCESS + "/okta/:id";
  paths.SETTINGS_SECURITY_IDP_LIMIT_ACCESS_AZURE_AD =
    paths.SETTINGS_SECURITY_IDP_LIMIT_ACCESS + "/azure-ad/:id";
  paths.SETTINGS_SECURITY_IDP_LIMIT_ACCESS_AD_LDAP =
    paths.SETTINGS_SECURITY_IDP_LIMIT_ACCESS + "/ad-ldap/:id";

  paths.SETTINGS_SECURITY_IDP_LIMIT_ACCESS_SAML =
    paths.SETTINGS_SECURITY_IDP_LIMIT_ACCESS + "/saml/:id";

  paths.BILLING = ROOT + "/billing";
  paths.BILLING_MANAGE_PLAN = paths.BILLING + "/manage-plan";
  paths.BILLING_CLIENTS = paths.BILLING + "/clients";
  paths.BILLING_INVOICES = paths.BILLING + "/invoices";
  paths.BILLING_DETAILS = paths.BILLING + "/billing-details";
  paths.BILLING_MANAGE_LICENSES =
    paths.BILLING_MANAGE_PLAN + "/licenses/:type/:action";
  paths.BILLING_CHANGE_PLAN = paths.BILLING + "/change-plan";
  paths.BILLING_CHANGE_PLAN_TO = paths.BILLING_CHANGE_PLAN + "/:plan";
  paths.BILLING_CHANGE_PLAN_TO_V1 = paths.BILLING_CHANGE_PLAN + "-v1/:plan";
  paths.BILLING_ADD_PAYMENT_METHOD = paths.BILLING_CHANGE_PLAN + "/add-method";
  paths.BILLING_CANCEL_SUBSCRIPTION = paths.BILLING + "/cancel-subscription";
  paths.BILLING_TALK_TO_US = paths.BILLING + "/talk-to-us";
  paths.BILLING_CANCELATION_FORM = paths.BILLING + "/cancelation";
  paths.BILLING_PAYMENT_METHODS = paths.BILLING + "/payment-methods";

  paths.SETTINGS_INTEGRATIONS = paths.SETTINGS + "/integrations";
  paths.SETTINGS_SPLUNK_POPUP = paths.SETTINGS_INTEGRATIONS + "/splunk";
  paths.SETTINGS_AZURE_SENTINEL_POPUP =
    paths.SETTINGS_INTEGRATIONS + "/azure-sentinel";
  paths.SETTINGS_AMAZON_S3_POPUP = paths.SETTINGS_INTEGRATIONS + "/amazon-s-3";
  paths.SETTINGS_CONNECT_WISE_POPUP =
    paths.SETTINGS_INTEGRATIONS + "/connectWise";
  paths.SETTINGS_CONNECT_WISE_MANAGE =
    paths.SETTINGS_CONNECT_WISE_POPUP + "/manage";
  paths.SETTINGS_INTEGRATIONS_LOGS =
    paths.SETTINGS_INTEGRATIONS + "/:integrationId/logs";
  paths.SETTINGS_INTEGRATIONS_LOGS_EVENT_DETAILS =
    paths.SETTINGS_INTEGRATIONS_LOGS + "/event-details/:id";

  paths.SETTINGS_API_SUPPORT = paths.SETTINGS + "/api-support";
  paths.SETTINGS_API_SUPPORT_CREATE_KEY =
    paths.SETTINGS_API_SUPPORT + "/create-key";

  paths.DPC_PROFILES = ROOT + "/dpc-profiles";
  paths.OBJECTS = ROOT + "/objects";
  paths.OBJECTS_ADDRESSES = paths.OBJECTS + "/addresses";
  paths.OBJECTS_SERVICES = paths.OBJECTS + "/services";
  paths.OBJECTS_CUSTOMURLS = paths.OBJECTS + "/custom-urls";

  paths.OBJECTS_ADDRESSES_ADD = paths.OBJECTS_ADDRESSES + "/add";
  paths.OBJECTS_ADDRESSES_EDIT = paths.OBJECTS_ADDRESSES + "/:id/edit";
  paths.OBJECTS_ADDRESSES_DUPLICATE =
    paths.OBJECTS_ADDRESSES + "/:id/duplicate";

  paths.OBJECTS_CUSTOMURLS_ADD = paths.OBJECTS_CUSTOMURLS + "/add";
  paths.OBJECTS_CUSTOMURLS_EDIT = paths.OBJECTS_CUSTOMURLS + "/:id/edit";
  paths.OBJECTS_CUSTOMURLS_DUPLICATE =
    paths.OBJECTS_CUSTOMURLS + "/:id/duplicate";

  paths.OBJECTS_SERVICES_ADD = paths.OBJECTS_SERVICES + "/add";
  paths.OBJECTS_SERVICES_EDIT = paths.OBJECTS_SERVICES + "/:id/edit";
  paths.OBJECTS_SERVICES_DUPLICATE = paths.OBJECTS_SERVICES + "/:id/duplicate";

  paths.SECURITY = ROOT + "/security";
  paths.SECURITY_WEB_FILTER_RULES = paths.SECURITY + "/web-filter-rules";
  paths.SECURITY_BYPASS_RULES = paths.SECURITY + "/bypass-rules";
  paths.SECURITY_THREAT_PREVENTION_RULES = "/threat-prevention-rules";
  paths.SECURITY_BROWSER_SECURITY = paths.SECURITY + "/browser-security";

  paths.DASHBOARD = ROOT + "/dashboard";
  return paths;
}
