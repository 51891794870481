import { identity } from "tools/function";
export { tryGet } from "safervpn-storybook/src/tools/object";

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function arrayToMap(byProp, array, transform = identity) {
  return array.reduce((map, entry) => {
    map[entry[byProp]] = transform(entry);
    return map;
  }, {});
}

export function arrayToStringByProp(propName, byProp) {
  return (data) => ({
    ...data,
    [propName]: data[propName].map((prop) => prop[byProp]).join(", "),
  });
}

export function hasValues(obj) {
  for (let key in obj) {
    if (obj[key] !== null && typeof obj[key] === "object") {
      return hasValues(obj[key]);
    }
    if (obj[key]) return true;
  }
  return false;
}

export function isUniqArray(array) {
  return array.every(
    (value, index, self) => self.findIndex((item) => item === value) === index
  );
}

export function uniq(array) {
  return Array.from(new Set(array));
}

export function haveCommonItems(arr1, arr2) {
  return arr1.some((item) => arr2.includes(item));
}

export function haveCommonItemsOrEmpty(arr1, arr2) {
  if (arr2.length === 0) return true;
  return haveCommonItems(arr1, arr2);
}

export function moveArrayItem(array, from, to) {
  array = [...array];
  array.splice(to, 0, array.splice(from, 1)[0]);
  return array;
}

export function addObjectIdField(obj) {
  if (!obj.objectId) {
    obj.objectId = obj.id || obj._id;
  }
  return obj;
}

export function keysToValues(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k]));
}
