export let ffKeys = {
  DASHBOARD_MONITORING: "ffKey.dashboard",
  SWG: "ffKey.swg",
  SWG_LOGS: "ffKey.web.activity",
  HA_TUNNELS: "ffKey.ha.tunnels",
  ACTIVE_SESSIONS: "ffKey.monitorlogs.activesessions",
  BANDWIDTH_CHART: "ffKey.bandwidthchart",
  AUDIT_LOGS: "ffKey.audit.logs",
  MALWARE_PROTECTION_ACTIVITY: "ffKey.malware.protection.activity",
  TRUE_ALWAYS_ON: "ffKey.true.always.on",
  TRUE_FQDN_OBJECTS: "ffKey.true.fqdn.objects",
  MAC_DNS_VIA_VPN: "ffKey.mac.dns.via.vpn",
  NEW_DESIGN: {
    MEMBER_PAGE: "ffKey.newdesign.members",
  },
  ALLOW_CHROMEBOOK: "ffKey.allow.chromebook",
  INFRASTRUCTURE_EVENTS: "ffKey.infrastructure.events",
  FW_EVENTS: "ffKey.fw.events",
  SWG_DEFAULT_RULES: "ffKey.swg.default.rules",
  PUBLIC_API_SCOPES: "ffKey.public.api.scopes",
  ADDRESS_SPLIT_TUNNELING: "ffKey.address.split.tunneling",
  EXCLUDE_SPLIT_TUNNELING: "ffKey.exclude.split.tunneling",
  FQDN_SPLIT_TUNNELING: "ffKey.fqdn.split.tunneling",
  SWG_USER_PROFILES: "ffKey.swg.user.profiles",
  CONNECTWISE_INTEGRATION: "ffKey.connectwise.integration",
  SWG_MDM: "ffKey.swg.mdm",
  SHOW_NEW_LEFT_MENU: "ffKey.show.new.left.menu",
  SWG_WIDGETS: "ffKey.swg.widgets",
  DEVICE_INVENTORY: "ffKey.device.inventory",
  NEW_GENERATION_NETWORK: "ffKey.next.generation.network",
  USER_PROFILE_SNOWPLOW: "ffKey.userprofile.snowplow",
  FW_ENABLE_EVENTS: "ffKey.fw.enable.events",
  UI_EVENTS_YARKON_FLOW: "ffKey.ui.events.yarkon.flow",
  BROWSER_SECURITY: "ffKey.browser.security",
  SWG_WILDCARD_ENABLE: "ffKey.swg.wildcard.enable",
  ZTA_NATIVE_RDP: "ffKey.zta.native.rdp",
  HYBRID_SPLIT_TUNNELING: "ffKey.hybrid.sase.split.tunneling",
  DYNAMIC_USER_PROPERTIES: "ffKey.dynamic.user.properties",
  SWG_THREAT_PREVENTION: "ffKey.swg.threat.prevention",
  SWG_THREAT_PREVENTION_ZP: "ffKey.swg.threat.prevention.zp",
  SWG_THREAT_PREVENTION_TE: "ffKey.swg.threat.prevention.te",
  SWG_THREAT_PREVENTION_AB: "ffKey.swg.threat.prevention.ab",
  TRUSTED_NETWORK_ENHANCEMENT: "ffKey.trusted.networks.enhancement",
  CUSTOMER_ADMIN: "ffKey.customer.admin",
  ALLOW_OVERLAPPING_SUBNETS: "ffKey.allow.overlapping.subnets",
  SWG_URL_ALLOW: "ffKey.swg.web.url.allow",
  APPLICATION_CONTROL: 'ffKey.swg.application.control',
  INSTALLATION_KEYS: "ffKey.installation.keys",
};

let featureFlags = new Map();

export function setFeatureFlags(flags) {
  featureFlags = new Map(flags.map((f) => [f]));
}

export function hasFeatureFlag(flag) {
  return featureFlags.has(flag);
}

export function hasOneOfFeatureFlags(flags) {
  return flags.some((flag) => hasFeatureFlag(flag));
}
